body, html {
  text-align: center;
}

h1, .ant-result-title { font-weight: 700; font-size: 32px }
.ant-result-extra { margin-top: 0; margin-bottom: 3rem; }

h4 {
  text-transform: uppercase;
  font-weight: 600;
  color: #aaa;
}


pre { text-align: left;}

.lead {
  font-size: 1.5rem;
  padding: 0 10%;
  color: #777;
  line-height: 1.75rem;
}
