.userManager {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: auto;
}

.userCard {
  margin: auto;
  transition:0.3s all;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 3px 12px #f3f3f3;
  border: 1px solid #f9f9f9;
  cursor: pointer;;
}



.inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto;
  padding: 0.5rem 1rem;
}

.vertical {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
}

.userCard:hover {
  background: #f3f3f3;
}

.userInfo {
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 1rem;
  flex-direction: column;
}

.avatar {
  /* vertical-align: middle; */
  /* background-color: #f56a00; */
  margin: 0.25rem 0.5rem;
  padding: 0;
  /* height: 96px;
  width: 96px; */
}

.userCard h2 {
  font-size: 19px;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.userCard p {
  font-size: 12px;
  line-height: 14px;
  opacity: 0.85;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}


.labelHeader {
  padding-bottom: 1em;
  margin-bottom: 2em;
  border-bottom: 1px solid #eee
}
